import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import AppTheme from "../../Theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FlagIcon from "@mui/icons-material/Flag";
import MovingIcon from "@mui/icons-material/Moving";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const proxy = process.env.REACT_APP_PROXY;
function General() {
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [KPIsRecords, setKPIsRecords] = useState([]);
  const [classifiedKPIs, setClassifiedKPIs] = useState({
    withinRange: [],
    acceptable: [],
    critical: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const response = await axios.get(
          `${proxy}/initiatives/getAllInitiative`
        );
        setinItativeRecords(response.data.data);
      } catch (error) {
        console.error("Error fetching initiatives:", error);
      }
    };

    fetchInitiatives(); // Call the function when the component mounts
  }, []);

  useEffect(() => {
    const fetchKPIs = async () => {
      try {
        const response = await axios.get(`${proxy}/kpi/getAllKPIs`);
        const kpis = response.data.data;

        // Classify KPIs
        const classified = classifyKPIs(kpis);
        setClassifiedKPIs(classified);
        setKPIsRecords(kpis);
      } catch (error) {
        console.error("Error fetching KPIs:", error);
      }
    };

    fetchKPIs();
  }, []);

  // Function to classify KPIs
  const classifyKPIs = (kpis) => {
    const withinRange = [];
    const acceptable = [];
    const critical = [];

    kpis.forEach((kpi) => {
      const target = kpi.Target_Value || 0;
      const actual = kpi.Actual_Value || 0;
      const percentage = isNaN(actual / target) ? 0 : (actual / target) * 100;

      if (percentage >= 90 && percentage <= 110) {
        withinRange.push(kpi);
      } else if (percentage >= 70 && percentage < 90) {
        acceptable.push(kpi);
      } else {
        critical.push(kpi);
      }
    });

    return { withinRange, acceptable, critical };
  };
  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box sx={{ width: "100%", flexGrow: 1, padding: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                cursor: "pointer", // Ensure the cursor is pointer
                width: "100%",
              }}
              onClick={() => navigate("/initiatives")}
            >
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  padding: 2,
                  marginBottom: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h2"
                    component="div"
                    sx={{ color: "#0A5EB0" }}
                  >
                    {initativeRecords.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: 18 }}
                  >
                    Number of Initiatives for the Current Year
                  </Typography>
                </Box>
                <FlagIcon sx={{ fontSize: 60, color: "#0A5EB0" }} />
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                cursor: "pointer", // Ensure the cursor is pointer
                width: "100%",
              }}
              onClick={() => navigate("/kpi")}
            >
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  padding: 2,
                  marginBottom: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h2"
                    component="div"
                    sx={{ color: "#0A5EB0" }}
                  >
                    {KPIsRecords.length}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: 18 }}
                  >
                    Performance and Impact Indicator
                  </Typography>
                </Box>
                <MovingIcon sx={{ fontSize: 60, color: "#0A5EB0" }} />
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                padding: 1,
                marginBottom: 1,
                display: "flex",
                justifyContent: "center",
                fontSize: "22px",
              }}
            >
              Initiatives Status
            </Paper>
          </Grid>

          {[
            "Successfully Implemented",
            "In progress",
            "Delayed",
            "Cancelled",
          ].map((status, index) => {
            const colors = ["#52b202", "#0A5EB0", "#EB5B00", "#FF2929"];
            const statusMapping = {
              "Successfully Implemented": "Completed",
              "In progress": "In progress",
              Delayed: "Delayed",
              Cancelled: "Canceled",
            };
            const count = initativeRecords.filter(
              (item) =>
                item.Status.toLowerCase() ===
                statusMapping[status].toLowerCase()
            ).length;
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                textAlign="center"
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    height: "250px",
                    padding: 1,
                    marginBottom: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Gauge
                    width={200}
                    height={200}
                    value={(count / initativeRecords.length) * 100} // Ensure the value is the percentage (0-100)
                    startAngle={-110}
                    endAngle={110}
                    sx={{
                      [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 20,
                      },
                      [`& .${gaugeClasses.valueArc}`]: {
                        fill: colors[index], // Ensure this dynamically changes based on the status
                      },
                    }}
                    text={
                      ({ value }) => `${Math.round(value)}%` // Round the value for clarity
                    }
                  />

                  <Typography variant="body2">{status}</Typography>
                </Paper>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                width: "100%",
                padding: 1,
                marginBottom: 2,
                marginTop: 2,
                display: "flex",
                justifyContent: "center",
                fontSize: "22px",
              }}
            >
              Indicators Status
            </Paper>
          </Grid>

          {[
            {
              label: "Performance Indicator within Target Range",
              category: "withinRange",
              color: "#52b202",
            },
            {
              label: "Acceptable Indicator Result",
              category: "acceptable",
              color: "#0A5EB0",
            },
            {
              label: "Critical Indicator",
              category: "critical",
              color: "#FF2929",
            },
          ].map((indicator, index) => {
            const count = classifiedKPIs[indicator.category].length;
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                textAlign="center"
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    height: "250px",
                    padding: 1,
                    marginBottom: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Gauge
                    width={200}
                    height={200}
                    value={
                      isNaN((count / KPIsRecords.length) * 100)
                        ? 0
                        : (count / KPIsRecords.length) * 100
                    } // Percentage of KPIs in this category
                    startAngle={-110}
                    endAngle={110}
                    sx={{
                      [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 20,
                      },
                      [`& .${gaugeClasses.valueArc}`]: {
                        fill: indicator.color,
                      },
                    }}
                    text={({ value }) => `${Math.round(value)}%`}
                  />
                  <Typography variant="body2">{indicator.label}</Typography>
                </Paper>
              </Grid>
            );
          })}

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  padding: 2,
                  marginBottom: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  maxHeight: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexDirection: "column", // Ensures content is stacked
                  minHeight: "200px", // Sets a minimum height
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#0A5EB0", overflow: "hidden" }}
                  >
                    Late Initiatives
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 18, overflow: "hidden" }}
                  >
                    <ul style={{ paddingLeft: "20px" }}>
                      {initativeRecords
                        .filter(
                          (initiative) =>
                            initiative.Status.toLowerCase() === "delayed"
                        )
                        .map((delayedInitiative, index) => (
                          <li
                            key={index}
                            style={{
                              wordBreak: "break-word", // Ensures long text wraps
                              overflowWrap: "break-word", // Handles long words breaking to a new line
                              whiteSpace: "normal", // Allows normal text wrapping
                            }}
                          >
                            {delayedInitiative.Initiative_Name ||
                              delayedInitiative.Initiative_Name ||
                              "Unnamed Initiative"}
                          </li>
                        ))}
                    </ul>
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper
                elevation={3}
                sx={{
                  width: "100%",
                  padding: 2,
                  marginBottom: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  maxHeight: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexDirection: "column", // Matches the layout
                  minHeight: "200px", // Ensures consistent height
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#0A5EB0", overflow: "hidden" }}
                  >
                    On Track Initiatives
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 18, overflow: "hidden" }}
                  >
                    <ul style={{ paddingLeft: "20px" }}>
                      {initativeRecords
                        .filter(
                          (initiative) =>
                            initiative.Status.toLowerCase() === "in progress"
                        )
                        .map((delayedInitiative, index) => (
                          <li
                            key={index}
                            style={{
                              wordBreak: "break-word", // Ensures long text wraps
                              overflowWrap: "break-word", // Handles long words breaking to a new line
                              whiteSpace: "normal", // Allows normal text wrapping
                            }}
                          >
                            {delayedInitiative.Initiative_Name ||
                              delayedInitiative.Initiative_Name ||
                              "Unnamed Initiative"}
                          </li>
                        ))}
                    </ul>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </AppTheme>
  );
}

export default General;
