import React, { useState, useEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/signin/SignIn";
import Dahsboard from "./pages/Dashboard/Dahsboard";
import Home from "./pages/Home";
import SideMenu from "./components/SideMenu/SideMenu";
import KPI from "./pages/KPI/KPI";
import KRI from "./pages/KRI/KRI";
import StrategicPlan from "./pages/StrategicPlan/StrategicPlan";
import Objectives from "./pages/Objectives/Objectives";
import Initiatives from "./pages/initiatives/Initiatives";
import User from "./pages/userDepartment/User";
import { styled } from "@mui/material/styles";
import { UserProvider } from "./UserContext";
import ResetPassword from "./pages/signin/ResetPassword";
import { getSession } from "./utils/auth";
import { useMediaQuery, useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerWidth, setDrawerWidth] = useState(
    localStorage.getItem("drawerWidth") || (isMobile ? 0 : 240)
  );

  // Update drawerWidth based on screen size and store it in localStorage
  useEffect(() => {
    if (isMobile) {
      setDrawerWidth(0); // Hide drawer on mobile
      localStorage.setItem("drawerWidth", 0);
    } else {
      setDrawerWidth(250); // Default width for desktop
      localStorage.setItem("drawerWidth", 240);
    }
  }, [isMobile]);

  const MainContent = styled("div")(({ theme }) => ({
    marginLeft: drawerWidth,
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fafafa",
    minHeight: "100vh",
    boxSizing: "border-box",
  }));

  return (
    <MainContent>
      <SideMenu />
      <Outlet />
    </MainContent>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      // Redirect the index path to /dashboard
      { path: "/", element: <Navigate to="/dashboard" replace /> },

      // Define the other routes
      { path: "/dashboard", element: <Dahsboard /> },
      { path: "/home", element: <Home /> },
      { path: "/kpi", element: <KPI /> },
      { path: "/kri", element: <KRI /> },
      { path: "/strategicPlan", element: <StrategicPlan /> },
      { path: "/objectives", element: <Objectives /> },
      { path: "/initiatives", element: <Initiatives /> },
      { path: "/Users", element: <User /> },

      // Catch-all route to redirect to /dashboard if path is not found
      { path: "*", element: <Navigate to="/dashboard" replace /> },
    ],
  },
  { path: "/Login", element: <SignIn /> },
  { path: "/reset-password", element: <ResetPassword /> },
]);

function App() {
  return (
    <UserProvider>
      <CssBaseline enableColorScheme />
      <InnerApp />
    </UserProvider>
  );
}

const InnerApp = () => {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
