import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuContent from "./MenuContent"; // Ensure this component exists
import OptionsMenu from "./OptionMenu"; // Ensure this component exists
import { useUser } from "../../UserContext"; // Ensure this is correct path
import useMediaQuery from "@mui/material/useMediaQuery";

const Drawer = styled(MuiDrawer)(({ theme, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    background: "#f7f9fb", // Gradient background
  },
}));

const SideMenu = ({ defaultDrawerWidth = 240 }) => {
  const { user } = useUser();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(
    localStorage.getItem("drawerWidth") || defaultDrawerWidth
  );

  // Update drawer width based on screen size
  React.useEffect(() => {
    if (isSmallScreen) {
      setDrawerWidth(0); // Hide drawer on small screens
      localStorage.setItem("drawerWidth", 0); // Persist in localStorage
    } else {
      setDrawerWidth(defaultDrawerWidth); // Default width for large screens
      localStorage.setItem("drawerWidth", defaultDrawerWidth); // Persist in localStorage
    }
  }, [isSmallScreen, defaultDrawerWidth]);

  // Toggle drawer visibility on small screens
  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      {isSmallScreen && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{ ml: 2, mt: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"} // Use temporary for small screens
        open={isSmallScreen ? open : true} // Control visibility for small screens
        onClose={toggleDrawer}
        drawerWidth={drawerWidth}
        sx={{
          display: { xs: "block", md: "block" }, // Ensure it displays on desktop
        }}
      >
        <MenuContent />
        <Stack
          direction="row"
          sx={{
            p: 2,
            gap: 1,
            alignItems: "center",
            borderTop: "1px solid",
            borderColor: "divider",
          }}
        >
          <Avatar
            sizes="small"
            alt={user?.name}
            src="/static/images/avatar/7.jpg"
            sx={{ width: 36, height: 36, color: "#242424 " }}
          />
          <Box sx={{ mr: "auto" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 800, lineHeight: "16px", color: "#242424 " }}
            >
              {user?.name || "Guest"}
            </Typography>
            <Typography variant="caption" sx={{ color: "#242424 " }}>
              {user?.email || "example@email.com"}
            </Typography>
          </Box>
          <OptionsMenu />
        </Stack>
      </Drawer>
    </>
  );
};

export default SideMenu;
